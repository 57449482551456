<template>
  <div class="profile-dropdown" :class="{ 'is-open elevation-10': profileDropdown }" v-if="teamMemberInfo && teamMemberInfo.Email">
    <NotificationDropDown
      :updateNotificationListFlag="updateNotificationListFlag"
      :userId="teamMemberInfo && teamMemberInfo.UserAccountsId"
      @errorRaise="errorRaise"
      :viewAllRoute="`/notifications`"
      @successRaise="successRaise"
      class="notification-drp"
      :isTeamMember="true"
    />
    <v-menu v-model="profileMenu" location="bottom">
      <template v-slot:activator="{ props }">
        <div class="user-dropdown" v-bind="props">
          <div class="user-name hidden-md-and-down">{{ teamMemberInfo.FirstName + " " + teamMemberInfo.LastName }}</div>
          <div v-if="teamMemberInfo.PhotoUrl">
            <v-img :src="teamMemberInfo.PhotoUrl" alt="profile-photo" class="profile-photo" />
          </div>
          <v-icon v-else :color="profileDropdown ? 'primary' : 'secondary'" class="profile-photo">mdi-account-circle</v-icon>
        </div>
      </template>

      <v-card min-width="300">
        <v-card-text>
          <v-card class="menu-user-email">
            <div>
              <router-link to="/team-member/profile" v-if="emailVerified">
                {{ teamMemberInfo.Email }}
              </router-link>
              <router-link to="" v-else>
                {{ teamMemberInfo.Email }}
              </router-link>
            </div>
          </v-card>

          <v-list class="">
            <v-list-item to="/team-member/timesheet" v-if="commitments && commitments.length > 0">
              <v-list-item-content>
                <v-list-item-title>Timesheet</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/register/logout">
              <v-list-item-content>
                <v-list-item-title>Log Out</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters, mapMutations } from "vuex";
import { TOGGLE_PROFILE_DROPDOWN, CLOSE_PROFILE_DROPDOWN } from "@/store/types";
import NotificationDropDown from "shared-components/src/components/Notification/NotificationDropDown.vue";
import store from "@/store";

export default defineComponent({
  props: ["teamMember", "commitments"],
  data() {
    return {
      loading: false,
      showDetailPopUp: false,
      profileMenu: false,
      emailVerified: store?.state?.userInfo.emailVerified ?? false,
    };
  },
  components: {
    NotificationDropDown,
  },
  computed: {
    ...mapGetters(["userInfo", "teamMemberInfo", "profileDropdown", "updateNotificationListFlag"]),
    hideName() {
      return !this.profileDropdown && this.$vuetify.display.name === "xs";
    },
  },
  // mounted() {
  //   // Check if authorization code is present in the URL
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const code = urlParams.get('code') ?? "";
  //   const state = urlParams.get('state') ?? "";

  //   console.log("before exchange token:", code);
  //   if (code) {
  //     // Exchange the authorization code for an access token
  //     // This should be done on the server side
  //     this.exchangeCodeForToken(code, state);
  //   }
  // },
  methods: {
    ...mapMutations([TOGGLE_PROFILE_DROPDOWN, CLOSE_PROFILE_DROPDOWN]),
    errorRaise(msg: any) {
      store.dispatch("showErrorMessage", msg);
    },
    successRaise(msg: any) {
      store.dispatch("showSuccessMessage", msg);
    },
  },
  directives: {
    closeOnClick: {
      beforeMount(el: any, binding: any, vnode: any) {
        document.addEventListener("click", (event) => onClick(event, el));
        function onClick(event: any, element: any) {
          const clickedELement = event.target;
          const container = element;
          let clickedItemSelector;
          if (clickedELement.classList.value !== "") {
            clickedItemSelector = "." + clickedELement.classList.value.split(" ").join(".");
          } else if (clickedELement.id) {
            clickedItemSelector = "#" + clickedELement.id;
          } else if (clickedELement.parentElement && clickedELement.parentElement.classList) {
            clickedItemSelector = "." + clickedELement.parentElement.classList.value.split(" ").join(".") + " " + clickedELement.tagName;
          }
          try {
            const isInContainer = container.querySelector(clickedItemSelector) != null;
            if (!isInContainer) {
              //vnode.context.CLOSE_PROFILE_DROPDOWN();
            }
          } catch (e) {
            //vnode.context.CLOSE_PROFILE_DROPDOWN();
          }
        }
      },
    },
  },
});
</script>

<style scoped lang="scss">
.profile-dropdown {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  color: $c_eerie_black;
  transition: all 0.2s;
  align-items: center;
  gap: 5px;
  .profile-photo {
    width: 24px;
    height: 24px;
  }
  .user-dropdown {
    display: flex;
    gap: 4px;
    padding: 12px;
    cursor: pointer;
    width: max-content;
  }
  .notification-drp {
    .v-btn {
      .v-icon {
        color: $c_eerie_black !important;
      }
    }
  }
}
.menu-user-email {
  background-color: $c_bittersweet_2 !important;
  width: 100%;
  height: 45px;
  padding: 12px !important;
  text-align: center;
  text-transform: uppercase;
  a {
    color: $c_white !important;
    text-decoration: none;
  }
}
</style>
